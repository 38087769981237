import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({ data }) {
  //const post = data.markdownRemark;

  return (
    <Layout>
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.excerpt}
        keywords={data.markdownRemark.frontmatter.tags}
      />
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      <small>
        <i className="far fa-calendar" /> {data.markdownRemark.frontmatter.date}
      </small>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        title
        author
        date
      }
      html
    }
  }
`
